import React, { useCallback, useEffect, useState } from 'react';
import { Loading, Modal } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import communityLinksService from '../../../../services/communityLinksService';
import { GuildedServer } from '../../../../types';
import SelectableListItem from '../../../SelectableListItem';
import { groupAnnouncementsConfig } from '../../../../translation.config';
import DialogActionButtonsRow from '../../DialogActionButtonsRow';
import CommunityLinkIcon from '../../../../../../../images/community_link_icon.svg';

type Props = {
  handleCancel: () => void;
  handleContinue: (communityId: string) => void;
  handleError: () => void;
  openCreateCommunityDialog: () => void;
} & WithTranslationsProps;

const LinkCommunityServerSelectStage = ({
  handleCancel,
  handleContinue,
  handleError,
  openCreateCommunityDialog,
  translate
}: Props): JSX.Element => {
  const [guildedServers, setGuildedServers] = useState<Array<GuildedServer> | null>();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCommunityId, setSelectedCommunityId] = useState<string>();

  const fetchGuildedServers = useCallback(async () => {
    try {
      const response: Array<GuildedServer> = await communityLinksService.getGuildedServers();
      setGuildedServers(response);
      setIsLoading(false);
    } catch {
      handleError();
    }
  }, [handleError]);

  const onPrimaryClicked = useCallback(() => {
    if (selectedCommunityId) {
      handleContinue(selectedCommunityId);
    }
  }, [handleContinue, selectedCommunityId]);

  const handleNavigateToCreateFlow = useCallback(() => {
    handleCancel();
    openCreateCommunityDialog();
  }, [handleCancel, openCreateCommunityDialog]);

  useEffect(() => {
    // eslint-disable-next-line no-void
    void fetchGuildedServers();
  }, [fetchGuildedServers]);

  if (isLoading) {
    return <Loading />;
  }

  if (!guildedServers?.length) {
    return (
      <Modal.Body>
        <div>{translate('Description.NoGuildedServersFound')}</div>
        <DialogActionButtonsRow
          primaryButtonProps={{
            label: translate('Action.Continue'),
            onClick: handleNavigateToCreateFlow
          }}
          secondaryButtonProps={{
            label: translate('Action.Cancel'),
            onClick: handleCancel
          }}
        />
      </Modal.Body>
    );
  }

  return (
    <Modal.Body>
      <div>{translate('Description.SelectGuildedServer')}</div>
      <ul className='selectable-list-item-list'>
        {guildedServers.map(({ serverId, name, iconUrl, memberCount, isAdmin }) => {
          if (!isAdmin) {
            return null;
          }
          return (
            <SelectableListItem
              key={serverId}
              iconUrl={iconUrl ?? CommunityLinkIcon}
              title={name}
              subtitle={translate('Label.MembersCount', { memberCount })}
              selected={serverId === selectedCommunityId}
              onClick={() => setSelectedCommunityId(serverId)}
            />
          );
        })}
      </ul>
      <DialogActionButtonsRow
        primaryButtonProps={{
          label: translate('Action.Continue'),
          disabled: !selectedCommunityId,
          onClick: onPrimaryClicked
        }}
        secondaryButtonProps={{
          label: translate('Action.Cancel'),
          onClick: handleCancel
        }}
      />
    </Modal.Body>
  );
};

export default withTranslations(LinkCommunityServerSelectStage, groupAnnouncementsConfig);
