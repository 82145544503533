import React, { Fragment, useCallback, useState } from 'react';
import { Modal } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import CreateCommunityFormInputStage from './stages/create/CreateCommunityFormInputStage';
import CreateCommunityAccountFetchStage from './stages/create/CreateCommunityAccountFetchStage';
import CreateCommunityCreateStage from './stages/create/CreateCommunityCreateStage';
import { groupAnnouncementsConfig } from '../../translation.config';
import useDialogErrorState from '../../hooks/useDialogErrorState';
import CommunityErrorStage from './stages/common/CommunityErrorStage';

type Props = {
  groupId: number;
  defaultName: string;
  eventContext: string;
  onClose: () => void;
  onCreateSuccess: () => Promise<void>;
  openLinkCommunityDialog: () => void;
} & WithTranslationsProps;

enum CreateCommunityStage {
  FORM_INPUT,
  ACCOUNT_FETCH,
  CREATE
}

const CreateCommunityDialog = ({
  groupId,
  defaultName,
  eventContext,
  onClose,
  onCreateSuccess,
  openLinkCommunityDialog,
  translate
}: Props): JSX.Element => {
  const [currentStage, setCurrentStage] = useState<CreateCommunityStage>(
    CreateCommunityStage.FORM_INPUT
  );

  const [communityName, setCommunityName] = useState<string>('');

  const { errorState, handleError, clearError } = useDialogErrorState();
  const { hasError, canRetry } = errorState;

  const onCreateFlowCompleted = useCallback(() => {
    // eslint-disable-next-line no-void
    void onCreateSuccess();
    onClose();
  }, [onCreateSuccess, onClose]);

  const onModalClose = useCallback(() => {
    if (currentStage === CreateCommunityStage.CREATE) {
      onCreateFlowCompleted();
    } else {
      onClose();
    }
  }, [currentStage, onClose, onCreateFlowCompleted]);

  const goToAccountFetchStage = useCallback((newCommunityName: string) => {
    setCommunityName(newCommunityName);
    setCurrentStage(CreateCommunityStage.ACCOUNT_FETCH);
  }, []);

  const goToCreateStage = useCallback(() => {
    setCurrentStage(CreateCommunityStage.CREATE);
  }, []);

  const handleOpenLinkDialog = useCallback(() => {
    onClose();
    openLinkCommunityDialog();
  }, [onClose, openLinkCommunityDialog]);

  return (
    <Modal className='create-community-dialog' show size='md' backdrop='static'>
      <Modal.Header title={translate('Heading.CreateCommunity')} onClose={onModalClose} />
      {hasError ? (
        <CommunityErrorStage canRetry={canRetry} handleRetry={clearError} handleClose={onClose} />
      ) : (
        <Fragment>
          {currentStage === CreateCommunityStage.FORM_INPUT && (
            <CreateCommunityFormInputStage
              groupId={groupId}
              defaultName={defaultName}
              handleCancel={onClose}
              handleContinue={goToAccountFetchStage}
            />
          )}
          {currentStage === CreateCommunityStage.ACCOUNT_FETCH && (
            <CreateCommunityAccountFetchStage
              handleCancel={onClose}
              handleContinue={goToCreateStage}
              handleOpenLinkDialog={handleOpenLinkDialog}
              handleError={handleError}
            />
          )}
          {currentStage === CreateCommunityStage.CREATE && (
            <CreateCommunityCreateStage
              groupId={groupId}
              communityName={communityName}
              eventContext={eventContext}
              handleClose={onCreateFlowCompleted}
              handleError={handleError}
            />
          )}
        </Fragment>
      )}
    </Modal>
  );
};

export default withTranslations(CreateCommunityDialog, groupAnnouncementsConfig);
