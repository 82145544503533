import React, { useCallback, useMemo, useState } from 'react';
import { Modal } from 'react-style-guide';
import { CurrentUser } from 'Roblox';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { groupAnnouncementsConfig } from '../../../../translation.config';
import DialogActionButtonsRow from '../../DialogActionButtonsRow';
import CommunityAccountFetchStage from '../common/CommunityAccountFetchStage';
import GuildedUserInfoDisplay from '../../../GuildedUserInfoDisplay';
import groupAnnouncementsConstants from '../../../../constants/groupAnnouncementsConstants';

type Props = {
  handleCancel: () => void;
  handleContinue: () => void;
  handleOpenLinkDialog: () => void;
  handleError: () => void;
} & WithTranslationsProps;

const CreateCommunityAccountFetchStage = ({
  handleCancel,
  handleContinue,
  handleOpenLinkDialog,
  handleError,
  translate
}: Props): JSX.Element => {
  const [isAccountNotFound, setIsAccountNotFound] = useState(false);
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

  const handleAccountNotFound = useCallback(() => {
    setIsAccountNotFound(true);
  }, []);

  const onCheckboxChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setHasAcceptedTerms(event.target.checked);
  }, []);

  const acceptTermsLabelHtml = useMemo(
    () => ({
      __html: translate('Label.AcceptGuildedTerms', {
        termsLinkStart: `<a href="${groupAnnouncementsConstants.urls.guildedTermsUrl}" rel="noopener" target="_blank">`,
        termsLinkEnd: '</a>',
        privacyLinkStart: `<a href="${groupAnnouncementsConstants.urls.guildedPrivacyPolicyUrl}" rel="noopener" target="_blank">`,
        privacyLinkEnd: '</a>'
      })
    }),
    [translate]
  );

  const onKeyDownOpenLinkDialog = useCallback(
    (event: React.KeyboardEvent<HTMLAnchorElement>) => {
      if (event.key === 'Enter') {
        handleOpenLinkDialog();
      }
    },
    [handleOpenLinkDialog]
  );

  if (isAccountNotFound) {
    return (
      <Modal.Body>
        <GuildedUserInfoDisplay
          guildedUser={{ userId: 'placeholder', username: CurrentUser.name, profilePicture: '' }}
          caption={translate('Label.CreatingGuildedAccountAs')}
        />
        <div className='community-dialog-section align-left'>
          {translate('Description.CreatingGuildedAccount')}
        </div>
        <div className='community-dialog-section align-left'>
          {translate('Description.AlreadyHaveAGuildedAccount')}{' '}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            role='button'
            className='link-existing-community-link'
            onClick={handleOpenLinkDialog}
            onKeyDown={onKeyDownOpenLinkDialog}
            tabIndex={0}>
            {translate('Action.LinkExistingCommunity')}
          </a>
        </div>
        <div className='community-dialog-section accept-guilded-terms'>
          <input
            className='accept-guilded-terms-checkbox'
            id='accept-guilded-terms'
            type='checkbox'
            onChange={onCheckboxChanged}
            checked={hasAcceptedTerms}
            tabIndex={0}
          />{' '}
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor='accept-guilded-terms'>
            <span
              className='accept-guilded-terms-label'
              dangerouslySetInnerHTML={acceptTermsLabelHtml}
            />
          </label>
        </div>
        <DialogActionButtonsRow
          primaryButtonProps={{
            label: translate('Action.Continue'),
            disabled: !hasAcceptedTerms,
            onClick: handleContinue
          }}
          secondaryButtonProps={{
            label: translate('Action.Cancel'),
            onClick: handleCancel
          }}
        />
      </Modal.Body>
    );
  }

  return (
    <CommunityAccountFetchStage
      handleContinue={handleContinue}
      handleCancel={handleCancel}
      handleAccountNotFound={handleAccountNotFound}
      handleError={handleError}
    />
  );
};

export default withTranslations(CreateCommunityAccountFetchStage, groupAnnouncementsConfig);
