import React, { useCallback, useEffect, useState } from 'react';
import { Endpoints } from 'Roblox';
import { Thumbnail2d, ThumbnailAvatarHeadshotSize, ThumbnailTypes } from 'roblox-thumbnails';
import { Link } from 'react-style-guide';
import { BadgeSizes, VerifiedBadgeIconContainer } from 'roblox-badges';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { ShoutInfoResponse, UserData } from '../types';
import { groupAnnouncementsConfig } from '../translation.config';
import usersService from '../services/usersService';

export type UserCardProps = {
  announcement: ShoutInfoResponse;
} & WithTranslationsProps;
const UserCard = ({ announcement, intl }: UserCardProps): JSX.Element | null => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<UserData | null>(null);
  const fetchUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const userReponse: UserData[] = await usersService.fetchUserInfo(announcement.createdBy);
      if (userReponse.length) {
        setUser(userReponse[0]);
      }
    } catch (error) {
      // TODO: implement proper error handling
      setUser(null);
    } finally {
      setIsLoading(false);
    }
  }, [announcement.createdBy]);

  useEffect(() => {
    // eslint-disable-next-line no-void
    void fetchUser();
  }, [fetchUser]);

  if (isLoading || !user) {
    return null;
  }

  return (
    <div className='user-card'>
      <Link
        className='avatar-card-link'
        url={Endpoints.getAbsoluteUrl(`/users/${user.id}/profile`)}>
        <Thumbnail2d
          type={ThumbnailTypes.avatarHeadshot}
          size={ThumbnailAvatarHeadshotSize.size48}
          targetId={user.id}
          containerClass='user-card-avatar avatar-headshot-sm'
          altName={user.name}
        />
      </Link>
      <div className='user-card-body'>
        <div className='user-card-body-name-container'>
          <Link
            url={Endpoints.getAbsoluteUrl(`/users/${user.id}/profile`)}
            className='text-name name'>
            {user.displayName}
          </Link>
          {user.hasVerifiedBadge && (
            <span className='user-card-body-name-container-badge'>
              <VerifiedBadgeIconContainer size={BadgeSizes.SUBHEADER} />
            </span>
          )}
        </div>
        <div className='text-date-hint'>
          {intl.getDateTimeFormatter().getFullDate(announcement.createdAt)}
        </div>
      </div>
    </div>
  );
};
export default withTranslations(UserCard, groupAnnouncementsConfig);
