import React, { useCallback, useState } from 'react';
import { Modal } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { groupAnnouncementsConfig } from '../../../../translation.config';
import CommunityChannelSelectStage from '../common/CommunityChannelSelectStage';
import DialogActionButtonsRow from '../../DialogActionButtonsRow';
import groupAnnouncementsConstants from '../../../../constants/groupAnnouncementsConstants';

type Props = {
  communityId: string;
  handleCancel: () => void;
  handleContinue: (selectedAnnouncementChannelId: string) => void;
  handleError: () => void;
} & WithTranslationsProps;

const ChangeChannelChannelSelectStage = ({
  communityId,
  handleCancel,
  handleContinue,
  handleError,
  translate
}: Props): JSX.Element => {
  const [areChannelsNotFound, setAreChannelsNotFound] = useState(false);
  const handleChannelsNotFound = useCallback(() => {
    setAreChannelsNotFound(true);
  }, []);

  if (areChannelsNotFound) {
    return (
      <Modal.Body>
        <div>{translate('Description.NoAnnouncementChannelsFound')}</div>
        <DialogActionButtonsRow
          primaryButtonProps={{
            label: translate('Action.ViewCommunity'),
            href: groupAnnouncementsConstants.urls.getViewCommunityUrl(communityId)
          }}
          secondaryButtonProps={{
            label: translate('Action.Cancel'),
            onClick: handleCancel
          }}
        />
      </Modal.Body>
    );
  }

  return (
    <CommunityChannelSelectStage
      communityId={communityId}
      handleCancel={handleCancel}
      handleContinue={handleContinue}
      handleChannelsNotFound={handleChannelsNotFound}
      handleError={handleError}
    />
  );
};

export default withTranslations(ChangeChannelChannelSelectStage, groupAnnouncementsConfig);
