import React from 'react';

type ErrorDisplayProps = {
  title: string;
  message: string;
};

const ErrorDisplay = ({ title, message }: ErrorDisplayProps): JSX.Element => {
  return (
    <div className='error-display'>
      <div className='error-display-image' />
      <div className='error-display-content'>
        <h3>{title}</h3>
        <div>{message}</div>
      </div>
    </div>
  );
};

export default ErrorDisplay;
