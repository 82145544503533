import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-style-guide';
import { Thumbnail2d, ThumbnailGameIconSize, ThumbnailTypes } from 'roblox-thumbnails';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { groupAnnouncementsConfig } from '../../../../translation.config';
import groupAnnouncementsConstants from '../../../../constants/groupAnnouncementsConstants';
import DialogActionButtonsRow from '../../DialogActionButtonsRow';
import { ValidateCommunityResponse } from '../../../../types';
import communityLinksService from '../../../../services/communityLinksService';

type Props = {
  groupId: number;
  defaultName: string;
  handleContinue: (newCommunityName: string) => void;
  handleCancel: () => void;
} & WithTranslationsProps;

const getValidationErrorKey = async (
  communityName: string,
  groupId: number,
  options: { asyncValidation: boolean }
): Promise<string | null> => {
  if (communityName.length < groupAnnouncementsConstants.limits.communityNameMinLength) {
    return 'Error.CommunityNameValidationTooShort';
  }
  if (options.asyncValidation) {
    const validateCommunityResponse: ValidateCommunityResponse = await communityLinksService.validateCommunity(
      groupId,
      { name: communityName }
    );
    if (!validateCommunityResponse.nameIsValid) {
      return 'Error.CommunityNameValidationInvalid';
    }
  }
  return null;
};

const CreateCommunityFormInputStage = ({
  groupId,
  defaultName,
  handleContinue,
  handleCancel,
  translate
}: Props): JSX.Element => {
  const [newCommunityName, setNewCommunityName] = useState(defaultName);
  const [validationErrorKey, setValidationErrorKey] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userHasInteractedWithContinueButton, setUserHasInteractedWithContinueButton] = useState(
    false
  );

  const checkNameValidation = useCallback(
    async (newName: string, options: { asyncValidation: boolean }) => {
      setIsLoading(true);
      try {
        const errorKey = await getValidationErrorKey(newName, groupId, {
          asyncValidation: options.asyncValidation
        });
        setValidationErrorKey(errorKey);
      } finally {
        setIsLoading(false);
      }
    },
    [groupId]
  );

  const handleContinueClicked = useCallback(() => {
    // Mark that the user has attempted to continue
    setUserHasInteractedWithContinueButton(true);
    // eslint-disable-next-line no-void
    void checkNameValidation(newCommunityName, { asyncValidation: true });
  }, [newCommunityName, checkNameValidation]);

  useEffect(() => {
    if (userHasInteractedWithContinueButton && validationErrorKey === null && !isLoading) {
      handleContinue(newCommunityName);
    }
  }, [
    validationErrorKey,
    isLoading,
    newCommunityName,
    handleContinue,
    userHasInteractedWithContinueButton
  ]);

  const handleNameInputChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserHasInteractedWithContinueButton(false);
      const newName = event.target.value;
      setNewCommunityName(newName);
      // eslint-disable-next-line no-void
      void checkNameValidation(newName, { asyncValidation: false });
    },
    [checkNameValidation]
  );

  useEffect(() => {
    // eslint-disable-next-line no-void
    void checkNameValidation(defaultName, { asyncValidation: true });
  }, [checkNameValidation, defaultName]);

  return (
    <Modal.Body>
      <div className='create-community-form-image'>
        <Thumbnail2d
          type={ThumbnailTypes.groupIcon}
          targetId={groupId}
          size={ThumbnailGameIconSize.size150}
        />
      </div>
      <div className='community-dialog-section'>{translate('Description.NameYourCommunity')}</div>
      <div className='create-community-form-input'>
        <label className='text-label' htmlFor='create-community-name-input'>
          {translate('Label.CommunityName')}
        </label>
        <input
          id='create-community-name-input'
          type='text'
          focus-me='true'
          placeholder={defaultName}
          minLength={groupAnnouncementsConstants.limits.communityNameMinLength}
          maxLength={groupAnnouncementsConstants.limits.communityNameMaxLength}
          className='form-control input-field'
          autoComplete='off'
          onChange={handleNameInputChanged}
          value={newCommunityName}
        />
        <p className='create-community-form-input-error'>
          {validationErrorKey && translate(validationErrorKey)}
        </p>
      </div>
      <DialogActionButtonsRow
        primaryButtonProps={{
          label: translate('Action.Continue'),
          disabled: !!validationErrorKey || isLoading,
          onClick: handleContinueClicked
        }}
        secondaryButtonProps={{
          label: translate('Action.Cancel'),
          onClick: handleCancel
        }}
      />
    </Modal.Body>
  );
};

export default withTranslations(CreateCommunityFormInputStage, groupAnnouncementsConfig);
