import React from 'react';
import { Modal } from 'react-style-guide';
import { Thumbnail2d, ThumbnailGameIconSize, ThumbnailTypes } from 'roblox-thumbnails';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { groupAnnouncementsConfig } from '../../../../translation.config';
import { CommunityInfo } from '../../../../types';
import groupAnnouncementsConstants from '../../../../constants/groupAnnouncementsConstants';
import DialogActionButtonsRow from '../../DialogActionButtonsRow';

type Props = {
  groupId: number;
  communityInfo: CommunityInfo;
  loginToken?: string;
  handleClose: () => void;
} & WithTranslationsProps;

const CommunityLinkSuccessStage = ({
  groupId,
  communityInfo,
  loginToken,
  handleClose,
  translate
}: Props): JSX.Element => {
  return (
    <Modal.Body>
      <div className='community-link-success-banner'>
        <div className='community-link-success-banner-icon'>
          <Thumbnail2d
            type={ThumbnailTypes.groupIcon}
            targetId={groupId}
            size={ThumbnailGameIconSize.size150}
          />
        </div>
      </div>
      <div className='community-dialog-section'>
        <h3>{translate('Heading.CommunityLinkSuccess', { communityName: communityInfo.name })}</h3>
        <div>{translate('Description.CommunityLinkSuccess')}</div>
      </div>
      <DialogActionButtonsRow
        primaryButtonProps={{
          label: translate('Action.CreateAnnouncement'),
          href: groupAnnouncementsConstants.urls.getViewAnnouncementChannelUrl(
            communityInfo.communityId,
            communityInfo.announcementChannelId,
            loginToken
          )
        }}
        secondaryButtonProps={{
          label: translate('Action.ViewCommunity'),
          href: groupAnnouncementsConstants.urls.getViewCommunityUrl(
            communityInfo.communityId,
            loginToken
          )
        }}
      />
    </Modal.Body>
  );
};

CommunityLinkSuccessStage.defaultProps = {
  loginToken: undefined
};

export default withTranslations(CommunityLinkSuccessStage, groupAnnouncementsConfig);
