import type { TranslationConfig } from 'react-utilities';

export const groupAnnouncementsConfig: TranslationConfig = {
  common: [],
  feature: 'Feature.Groups'
};

export const communityLinkDisplayConfig: TranslationConfig = {
  common: [],
  feature: 'Feature.CommunityLinks'
};

export default { groupAnnouncementsConfig, communityLinkDisplayConfig };
