import classNames from 'classnames';
import React, { useCallback } from 'react';

type Props = {
  title: string;
  subtitle?: string;
  iconClassName?: string;
  iconUrl: string;
  selected: boolean;
  onClick: () => void;
};

const SelectableListItem = ({
  title,
  subtitle,
  iconClassName,
  iconUrl,
  selected,
  onClick
}: Props): JSX.Element => {
  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        onClick();
      }
    },
    [onClick]
  );

  return (
    <div
      className='selectable-list-item'
      onClick={onClick}
      onKeyDown={onKeyDown}
      tabIndex={0}
      role='radio'
      aria-checked={selected}>
      <img
        className={classNames('selectable-list-item-icon', iconClassName)}
        src={iconUrl}
        alt=''
      />
      <div className='selectable-list-item-info'>
        <div className='selectable-list-item-title'>{title}</div>
        {subtitle && <div className='selectable-list-item-subtitle'>{subtitle}</div>}
      </div>
      <div
        className={classNames(
          'selectable-list-item-checkbox',
          selected && 'selectable-list-item-checkbox-filled'
        )}
      />
    </div>
  );
};

SelectableListItem.defaultProps = {
  subtitle: null,
  iconClassName: null
};

export default SelectableListItem;
