import { EnvironmentUrls } from 'Roblox';

const { apiGatewayUrl, groupsApi, catalogApi } = EnvironmentUrls;

const communityLinksGroupsUrlPrefix = `${apiGatewayUrl}/community-links/v1/groups`;
const communityLinksGuildedUrlPrefix = `${apiGatewayUrl}/community-links/v1/guilded`;
const communityLinksShoutUrlPrefix = `${apiGatewayUrl}/community-links/v1/shout`;

const guildedAttributionSource = 'rgp'; // rgp = roblox group page

export default {
  urls: {
    getGroupCommunityInfoUrl(groupId: number): string {
      return `${communityLinksGroupsUrlPrefix}/${groupId}/community`;
    },
    getGroupCommunityLinkUrl(groupId: number): string {
      return `${communityLinksGroupsUrlPrefix}/${groupId}/community/link`;
    },
    getGroupShoutUrl(groupId: number): string {
      return `${communityLinksGroupsUrlPrefix}/${groupId}/shout`;
    },
    getUserCommunityChannelsUrl(communityId: string): string {
      return `${communityLinksGuildedUrlPrefix}/user/community/${communityId}/channels/announcements`;
    },
    getReactionIncrementUrl(): string {
      return `${communityLinksShoutUrlPrefix}/reactions/increment`;
    },
    getReactionDecrementUrl(): string {
      return `${communityLinksShoutUrlPrefix}/reactions/decrement`;
    },
    getGroupMembershipUrl(groupId: number): string {
      return `${groupsApi}/v1/groups/${groupId}/membership`;
    },
    getGroupNotificationPreferenceUrl(groupId: number): string {
      return `${groupsApi}/v1/groups/${groupId}/notification-preference`;
    },
    getCatalogItemDetailsUrl(itemType: string, id: number): string {
      return `${catalogApi}/v1/catalog/items/${id}/details?itemType=${itemType}`;
    },
    getViewCommunityUrl(communityId: string, loginToken?: string): string {
      return `${EnvironmentUrls.guildedBaseUrl}/teams/${communityId}?a=${guildedAttributionSource}${
        loginToken ? `&token=${loginToken}` : ''
      }`;
    },
    getJoinCommunityUrl(communityId: string): string {
      return `${communityLinksGuildedUrlPrefix}/${communityId}/join?a=${guildedAttributionSource}`;
    },
    getViewAnnouncementChannelUrl(
      communityId: string,
      channelId: string,
      loginToken?: string
    ): string {
      return `${
        EnvironmentUrls.guildedBaseUrl
      }/teams/${communityId}/channels/${channelId}/announcements?a=${guildedAttributionSource}${
        loginToken ? `&token=${loginToken}` : ''
      }`;
    },
    getGroupCommunityValidateUrl(groupId: number): string {
      return `${communityLinksGroupsUrlPrefix}/${groupId}/community/validate`;
    },
    getConfigureGroupGuildedSectionUrl(groupId: number): string {
      return `${EnvironmentUrls.websiteUrl}/groups/configure?id=${groupId}#!/guilded`;
    },
    guildedUserUrl: `${communityLinksGuildedUrlPrefix}/user`,
    guildedUserServersUrl: `${communityLinksGuildedUrlPrefix}/user/servers`,
    getUsersInfoURL: `${EnvironmentUrls.usersApi}/v1/users`,
    guildedTermsUrl: 'https://support.guilded.gg/hc/en-us/articles/360039728313-Terms-of-use',
    guildedPrivacyPolicyUrl: 'https://support.guilded.gg/hc/en-us/articles/360039235054-Privacy'
  },
  embedUrlRegexes: {
    catalogItem: /(roblox\.com|robloxlabs\.com)\/catalog\/(\d+)/
  },
  limits: {
    communityNameMinLength: 3,
    communityNameMaxLength: 30
  }
};
