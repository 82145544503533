import React, { useMemo } from 'react';
import { Modal } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { groupAnnouncementsConfig } from '../../../../translation.config';
import DialogActionButtonsRow from '../../DialogActionButtonsRow';
import ErrorDisplay from '../../../ErrorDisplay';

type Props = {
  canRetry: boolean;
  handleRetry: () => void;
  handleClose: () => void;
} & WithTranslationsProps;

const CommunityErrorStage = ({
  canRetry,
  handleRetry,
  handleClose,
  translate
}: Props): JSX.Element => {
  const primaryButtonProps = useMemo(() => {
    if (!canRetry) {
      return undefined;
    }
    return {
      label: translate('Action.Retry'),
      onClick: handleRetry
    };
  }, [canRetry, handleRetry, translate]);

  const secondaryButtonProps = useMemo(() => {
    return {
      label: translate('Action.Cancel'),
      onClick: handleClose
    };
  }, [handleClose, translate]);

  return (
    <Modal.Body>
      <ErrorDisplay
        title={translate('Heading.CommunityDialogError')}
        message={translate('Description.CommunityDialogError')}
      />
      <DialogActionButtonsRow
        primaryButtonProps={primaryButtonProps}
        secondaryButtonProps={secondaryButtonProps}
      />
    </Modal.Body>
  );
};

export default withTranslations(CommunityErrorStage, groupAnnouncementsConfig);
