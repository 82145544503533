import React, { useCallback, useEffect, useState } from 'react';
import { Loading } from 'react-style-guide';
import communityLinksService from '../../../../services/communityLinksService';
import { CreateCommunityResponse } from '../../../../types';
import CommunityLinkSuccessStage from '../common/CommunityLinkSuccessStage';
import { logCommunityCreateFlowCompleted } from '../../../../utils/logging';

type HandleErrorOptions = {
  canRetry: boolean;
};

type Props = {
  groupId: number;
  communityName: string;
  eventContext: string;
  handleClose: () => void;
  handleError: (options: HandleErrorOptions) => void;
};

const CreateCommunityCreateStage = ({
  groupId,
  communityName,
  eventContext,
  handleClose,
  handleError
}: Props): JSX.Element => {
  const [
    createdCommunityResponse,
    setCreatedCommunityResponse
  ] = useState<CreateCommunityResponse>();

  const createCommunity = useCallback(async () => {
    try {
      const response = await communityLinksService.createCommunity(groupId, communityName);
      setCreatedCommunityResponse(response);
      logCommunityCreateFlowCompleted({
        groupId,
        communityId: response.communityInfo.communityId,
        announcementChannelId: response.communityInfo.announcementChannelId,
        context: eventContext
      });
    } catch {
      handleError({ canRetry: false });
    }
  }, [groupId, communityName, eventContext, handleError]);

  useEffect(() => {
    // eslint-disable-next-line no-void
    void createCommunity();
  }, [createCommunity]);

  if (!createdCommunityResponse) {
    return <Loading />;
  }

  return (
    <CommunityLinkSuccessStage
      groupId={groupId}
      communityInfo={createdCommunityResponse.communityInfo}
      loginToken={createdCommunityResponse.loginToken}
      handleClose={handleClose}
    />
  );
};

export default CreateCommunityCreateStage;
