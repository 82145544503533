import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Loading } from 'react-style-guide';
import communityLinksService from '../../../../services/communityLinksService';
import { CommunityInfo } from '../../../../types';
import CommunityLinkSuccessStage from '../common/CommunityLinkSuccessStage';
import { logCommunityChangeChannelFlowCompleted } from '../../../../utils/logging';

type Props = {
  groupId: number;
  communityInfo: CommunityInfo;
  announcementChannelId: string;
  handleClose: () => void;
  handleError: () => void;
};

const ChangeChannelSetChannelStage = ({
  groupId,
  communityInfo,
  announcementChannelId,
  handleClose,
  handleError
}: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);

  const updatedCommunityInfo = useMemo(() => {
    return { ...communityInfo, announcementChannelId };
  }, [communityInfo, announcementChannelId]);

  const updateChannel = useCallback(async () => {
    try {
      await communityLinksService.updateLinkedCommunity(groupId, {
        announcementChannelId
      });
      logCommunityChangeChannelFlowCompleted({
        groupId,
        communityId: communityInfo.communityId,
        announcementChannelId,
        prevAnnouncementChannelId: communityInfo.announcementChannelId
      });
      setIsLoading(false);
    } catch {
      handleError();
    }
  }, [
    groupId,
    announcementChannelId,
    communityInfo.communityId,
    communityInfo.announcementChannelId,
    handleError
  ]);

  useEffect(() => {
    // eslint-disable-next-line no-void
    void updateChannel();
  }, [updateChannel]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <CommunityLinkSuccessStage
      groupId={groupId}
      communityInfo={updatedCommunityInfo}
      handleClose={handleClose}
    />
  );
};

export default ChangeChannelSetChannelStage;
