import React, { useCallback, useEffect, useState } from 'react';
import { Loading, Modal } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import communityLinksService from '../../../../services/communityLinksService';
import { GetGuildedChannelsResponse, GuildedChannel } from '../../../../types';
import SelectableListItem from '../../../SelectableListItem';
import { groupAnnouncementsConfig } from '../../../../translation.config';
import DialogActionButtonsRow from '../../DialogActionButtonsRow';
import ChannelIcon from '../../../../../../../images/channel_icon.svg';

type Props = {
  communityId: string;
  handleCancel: () => void;
  handleContinue: (selectedAnnouncementChannelId: string) => void;
  handleChannelsNotFound: () => void;
  handleError: () => void;
} & WithTranslationsProps;

const CommunityChannelSelectStage = ({
  communityId,
  handleCancel,
  handleContinue,
  handleChannelsNotFound,
  handleError,
  translate
}: Props): JSX.Element => {
  const [announcementChannels, setAnnouncementChannels] = useState<Array<GuildedChannel> | null>();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAnnouncementChannelId, setSelectedAnnouncementChannelId] = useState<string>();

  const fetchAnnouncementChannels = useCallback(async () => {
    try {
      const response: GetGuildedChannelsResponse = await communityLinksService.getLinkedCommunityChannels(
        communityId
      );
      const channels = response?.channels;
      if (!channels?.length) {
        handleChannelsNotFound();
        return;
      }
      setAnnouncementChannels(channels);
      setIsLoading(false);
    } catch {
      handleError();
    }
  }, [communityId, handleChannelsNotFound, handleError]);

  const onPrimaryClicked = useCallback(() => {
    if (selectedAnnouncementChannelId) {
      handleContinue(selectedAnnouncementChannelId);
    }
  }, [handleContinue, selectedAnnouncementChannelId]);

  useEffect(() => {
    // eslint-disable-next-line no-void
    void fetchAnnouncementChannels();
  }, [fetchAnnouncementChannels]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Modal.Body>
      <div>{translate('Description.SelectAnnouncementChannel')}</div>
      <ul className='selectable-list-item-list'>
        {(announcementChannels ?? []).map(({ id, name }) => {
          return (
            <SelectableListItem
              key={id}
              iconUrl={ChannelIcon}
              iconClassName='selectable-channel-icon'
              title={name}
              selected={id === selectedAnnouncementChannelId}
              onClick={() => setSelectedAnnouncementChannelId(id)}
            />
          );
        })}
      </ul>
      <DialogActionButtonsRow
        primaryButtonProps={{
          label: translate('Action.Continue'),
          disabled: !selectedAnnouncementChannelId,
          onClick: onPrimaryClicked
        }}
        secondaryButtonProps={{
          label: translate('Action.Cancel'),
          onClick: handleCancel
        }}
      />
    </Modal.Body>
  );
};

export default withTranslations(CommunityChannelSelectStage, groupAnnouncementsConfig);
