import React, { useCallback } from 'react';
import CommunityChannelSelectStage from '../common/CommunityChannelSelectStage';

type Props = {
  communityId: string;
  handleCancel: () => void;
  handleContinue: (selectedAnnouncementChannelId: string | null) => void;
  handleError: () => void;
};

const LinkCommunityChannelSelectStage = ({
  communityId,
  handleCancel,
  handleContinue,
  handleError
}: Props): JSX.Element => {
  const handleChannelsNotFound = useCallback(() => {
    handleContinue(null);
  }, [handleContinue]);

  return (
    <CommunityChannelSelectStage
      communityId={communityId}
      handleCancel={handleCancel}
      handleContinue={handleContinue}
      handleChannelsNotFound={handleChannelsNotFound}
      handleError={handleError}
    />
  );
};

export default LinkCommunityChannelSelectStage;
