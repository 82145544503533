import React, { Fragment, useCallback, useState } from 'react';
import { Modal } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import LinkCommunityAccountFetchStage from './stages/link/LinkCommunityAccountFetchStage';
import LinkCommunityServerSelectStage from './stages/link/LinkCommunityServerSelectStage';
import LinkCommunityChannelSelectStage from './stages/link/LinkCommunityChannelSelectStage';
import LinkCommunityCreateLinkStage from './stages/link/LinkCommunityCreateLinkStage';
import { groupAnnouncementsConfig } from '../../translation.config';
import useDialogErrorState from '../../hooks/useDialogErrorState';
import CommunityErrorStage from './stages/common/CommunityErrorStage';

type Props = {
  groupId: number;
  eventContext: string;
  onClose: () => void;
  onLinkSuccess: () => Promise<void>;
  openCreateCommunityDialog: () => void;
} & WithTranslationsProps;

enum LinkCommunityStage {
  ACCOUNT_FETCH,
  SERVER_SELECT,
  CHANNEL_SELECT,
  CREATE_LINK
}

const LinkCommunityDialog = ({
  groupId,
  eventContext,
  onClose,
  onLinkSuccess,
  openCreateCommunityDialog,
  translate
}: Props): JSX.Element => {
  const [currentStage, setCurrentStage] = useState<LinkCommunityStage>(
    LinkCommunityStage.ACCOUNT_FETCH
  );

  const [communityId, setCommunityId] = useState<string>('');
  const [announcementChannelId, setAnnouncementChannelId] = useState<string | null>();

  const { errorState, handleError, clearError } = useDialogErrorState();
  const { hasError, canRetry } = errorState;

  const onLinkFlowCompleted = useCallback(() => {
    // eslint-disable-next-line no-void
    void onLinkSuccess();
    onClose();
  }, [onLinkSuccess, onClose]);

  const onModalClose = useCallback(() => {
    if (currentStage === LinkCommunityStage.CREATE_LINK) {
      onLinkFlowCompleted();
    } else {
      onClose();
    }
  }, [currentStage, onClose, onLinkFlowCompleted]);

  const goToServerSelectStage = useCallback(() => {
    setCurrentStage(LinkCommunityStage.SERVER_SELECT);
  }, []);

  const goToChannelSelectStage = useCallback((selectedCommunityId: string) => {
    setCommunityId(selectedCommunityId);
    setCurrentStage(LinkCommunityStage.CHANNEL_SELECT);
  }, []);

  const goToCreateLinkStage = useCallback((selectedAnnouncementChannelId: string | null) => {
    setAnnouncementChannelId(selectedAnnouncementChannelId);
    setCurrentStage(LinkCommunityStage.CREATE_LINK);
  }, []);

  return (
    <Modal className='link-community-dialog' show size='md' backdrop='static'>
      <Modal.Header title={translate('Heading.LinkCommunity')} onClose={onModalClose} />
      {hasError ? (
        <CommunityErrorStage canRetry={canRetry} handleRetry={clearError} handleClose={onClose} />
      ) : (
        <Fragment>
          {currentStage === LinkCommunityStage.ACCOUNT_FETCH && (
            <LinkCommunityAccountFetchStage
              handleCancel={onClose}
              handleContinue={goToServerSelectStage}
              handleError={handleError}
            />
          )}
          {currentStage === LinkCommunityStage.SERVER_SELECT && (
            <LinkCommunityServerSelectStage
              handleCancel={onClose}
              handleContinue={goToChannelSelectStage}
              handleError={handleError}
              openCreateCommunityDialog={openCreateCommunityDialog}
            />
          )}
          {currentStage === LinkCommunityStage.CHANNEL_SELECT && (
            <LinkCommunityChannelSelectStage
              communityId={communityId}
              handleCancel={onClose}
              handleContinue={goToCreateLinkStage}
              handleError={handleError}
            />
          )}
          {currentStage === LinkCommunityStage.CREATE_LINK && (
            <LinkCommunityCreateLinkStage
              groupId={groupId}
              communityId={communityId}
              announcementChannelId={announcementChannelId ?? null}
              eventContext={eventContext}
              handleClose={onLinkFlowCompleted}
              handleError={handleError}
            />
          )}
        </Fragment>
      )}
    </Modal>
  );
};

export default withTranslations(LinkCommunityDialog, groupAnnouncementsConfig);
