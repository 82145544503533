import React from 'react';
import { Button } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { communityLinkDisplayConfig } from '../translation.config';
import { CommunityInfo } from '../types';
import groupAnnouncementsConstants from '../constants/groupAnnouncementsConstants';

// Note: onClick is not an optional parameter for a Button but is not needed when wrapped in A tag.
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noOpFunctionRef = () => {};

export type CommunityLinkDisplayProps = {
  communityInfo: CommunityInfo;
  groupName: string;
} & WithTranslationsProps;
const CommunityLinkDisplay = ({
  communityInfo,
  groupName,
  translate
}: CommunityLinkDisplayProps): JSX.Element => {
  return (
    <div className='section community-link-display'>
      <div className='container-header'>
        <h2>{translate('Heading.CommunityTitle')}</h2>
      </div>
      <a
        className='section-content community-link-display-link'
        href={groupAnnouncementsConstants.urls.getJoinCommunityUrl(communityInfo.communityId)}
        target='_blank'
        rel='noreferrer'>
        <div className='community-link-display-link-icon-container'>
          <div className='community-link-display-link-icon' />
        </div>
        <div className='community-link-display-link-info-container'>
          <div>
            <h5 className='community-link-display-link-name'>{communityInfo.name}</h5>
          </div>
          <div>
            <span className='community-link-display-link-description text-default'>
              {communityInfo.description ||
                translate('Description.CommunityDefault', {
                  experienceName: groupName
                })}
            </span>
          </div>
        </div>
        <div className='community-link-display-link-visit-container'>
          <Button variant={Button.variants.cta} onClick={noOpFunctionRef}>
            {translate('Label.ViewButton')}
          </Button>
        </div>
      </a>
    </div>
  );
};
export default withTranslations(CommunityLinkDisplay, communityLinkDisplayConfig);
