import React, { useEffect } from 'react';
import { Button } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { Group } from '../types';
import { logGroupPageExposureEvent } from '../utils/logging';
import { EventContext } from '../constants/events';
import { groupAnnouncementsConfig } from '../translation.config';
import CloseButton from './CloseButton';

export type NotificationUpsellBannerProps = {
  group: Group;
  eventContext: EventContext;
  onNotifyClicked: () => void;
  onDismiss: () => void;
} & WithTranslationsProps;

const NotificationUpsellBanner = ({
  group,
  eventContext,
  onNotifyClicked,
  onDismiss,
  translate
}: NotificationUpsellBannerProps): JSX.Element => {
  useEffect(() => {
    logGroupPageExposureEvent({
      groupId: group.id,
      exposureType: 'notificationsUpsellBannerSeen',
      context: eventContext
    });
  }, [group.id, eventContext]);

  return (
    <div className='group-notifications-upsell-banner'>
      <span className='group-notifications-upsell-banner-icon icon-common-notification-bell' />
      <div className='group-notifications-upsell-banner-content'>
        <h3 className='group-notifications-upsell-banner-title'>
          {translate('Heading.NotificationsUpsell')}
        </h3>
        <div className='group-notifications-upsell-banner-description'>
          {translate('Description.NotificationsUpsell', { groupName: group.name })}
        </div>
      </div>
      <Button
        variant={Button.variants.primary}
        size={Button.sizes.small}
        width={Button.widths.default}
        className='group-notifications-upsell-banner-notify'
        onClick={onNotifyClicked}>
        {translate('Action.NotifyMe')}
      </Button>
      <CloseButton className='group-notifications-upsell-banner-close' onClick={onDismiss} />
    </div>
  );
};

export default withTranslations(NotificationUpsellBanner, groupAnnouncementsConfig);
