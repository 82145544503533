import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { CommunityInfo, GetGuildedChannelsResponse } from '../types';
import LinkableButton from './LinkableButton';
import groupAnnouncementsConstants from '../constants/groupAnnouncementsConstants';
import { groupAnnouncementsConfig } from '../translation.config';
import communityLinksService from '../services/communityLinksService';

export type GuildedAnnouncementUpsellProps = {
  communityInfo: CommunityInfo;
  groupId: number;
} & WithTranslationsProps;

const GuildedAnnouncementUpsell = ({
  communityInfo,
  groupId,
  translate
}: GuildedAnnouncementUpsellProps): JSX.Element | null => {
  const [linkToConfigureGuildedSection, setLinkToConfigureGuildedSection] = useState(false);

  const checkIfChannelIsLinked = useCallback(async () => {
    if (communityInfo == null) return;

    try {
      const communityChannelsResponse: GetGuildedChannelsResponse = await communityLinksService.getLinkedCommunityChannels(
        communityInfo.communityId
      );

      const foundChannel = communityChannelsResponse.channels.some(channelResponse => {
        return channelResponse.id === communityInfo.announcementChannelId;
      });

      // Our channel was deleted or unlinked then we want the post first announcement upsell to link to configure guilded settings
      if (!foundChannel) {
        setLinkToConfigureGuildedSection(true);
      }
    } catch {
      // do nothing
    }
  }, [communityInfo]);

  useEffect(() => {
    if (communityInfo) {
      // eslint-disable-next-line no-void
      void Promise.resolve(checkIfChannelIsLinked());
    }
  }, [communityInfo, checkIfChannelIsLinked]);

  return (
    <div className='guilded-announcement-upsell'>
      <div className='guilded-announcement-upsell-banner' />
      <div className='guilded-announcement-upsell-description'>
        <h2>{translate('Heading.PostFirstAnnouncement')}</h2>
        <p>{translate('Description.PostFirstAnnouncement')}</p>
        <div className='guilded-announcement-upsell-description-button-container'>
          <LinkableButton
            href={
              linkToConfigureGuildedSection
                ? groupAnnouncementsConstants.urls.getConfigureGroupGuildedSectionUrl(groupId)
                : groupAnnouncementsConstants.urls.getViewAnnouncementChannelUrl(
                    communityInfo.communityId,
                    communityInfo.announcementChannelId
                  )
            }
            openInNewTab={!linkToConfigureGuildedSection}
            variant={Button.variants.primary}
            label={translate('Action.PostFirstAnnouncement')}
          />
        </div>
      </div>
    </div>
  );
};

export default withTranslations(GuildedAnnouncementUpsell, groupAnnouncementsConfig);
