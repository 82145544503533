import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { Button, IconButton, Popover } from 'react-style-guide';
import { groupAnnouncementsConfig } from '../translation.config';
import { CommunityInfo, GetGuildedChannelsResponse, GuildedChannel } from '../types';
import communityLinksService from '../services/communityLinksService';
import groupAnnouncementsConstants from '../constants/groupAnnouncementsConstants';
import UnlinkCommunityDialog from './dialogs/UnlinkCommunityDialog';
import ChangeChannelDialog from './dialogs/ChangeChannelDialog';
import LinkableButton from './LinkableButton';
import {
  logCommunityChangeChannelFlowStarted,
  logCommunityUnlinkFlowStarted
} from '../utils/logging';

type Props = {
  communityInfo: CommunityInfo;
  refetchCommunityInfo: () => Promise<void>;
  groupId: number;
} & WithTranslationsProps;

// Note: onClick is not an optional parameter for an IconButton but is not needed for the popover
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noOpFunctionRef = () => {};

const AnnouncementChannelConfigurator = ({
  groupId,
  communityInfo,
  translate,
  refetchCommunityInfo
}: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [channel, setChannel] = useState<GuildedChannel>();
  const [isChannelSelectorModalVisible, setIsChannelSelectorModalVisible] = useState(false);
  const [isUnlinkCommunityModalVisible, setIsUnlinkCommunityModalVisible] = useState(false);

  const showChannelSelectorModal = useCallback(() => {
    logCommunityChangeChannelFlowStarted({
      groupId,
      communityId: communityInfo.communityId,
      announcementChannelId: channel?.id ?? ''
    });
    setIsChannelSelectorModalVisible(true);
  }, [groupId, communityInfo.communityId, channel?.id]);

  const hideChannelSelectorModal = useCallback(() => {
    setIsChannelSelectorModalVisible(false);
  }, []);

  const showUnlinkCommunityModal = useCallback(() => {
    logCommunityUnlinkFlowStarted({
      groupId,
      communityId: communityInfo.communityId,
      announcementChannelId: channel?.id ?? ''
    });
    setIsUnlinkCommunityModalVisible(true);
  }, [groupId, communityInfo.communityId, channel?.id]);

  const hideUnlinkCommunityModal = useCallback(() => {
    setIsUnlinkCommunityModalVisible(false);
  }, []);

  const fetchCommunityChannels = useCallback(async () => {
    try {
      const communityChannelsResponse: GetGuildedChannelsResponse = await communityLinksService.getLinkedCommunityChannels(
        communityInfo.communityId
      );

      const foundChannel = communityChannelsResponse.channels.find(channelResponse => {
        return channelResponse.id === communityInfo.announcementChannelId;
      });

      if (foundChannel) {
        setChannel(foundChannel);
      }
    } finally {
      setIsLoading(false);
    }
  }, [communityInfo.communityId, communityInfo.announcementChannelId]);

  const onChannelUpdateSuccess = useCallback(async () => {
    await refetchCommunityInfo();
    await fetchCommunityChannels();
  }, [refetchCommunityInfo, fetchCommunityChannels]);

  useEffect(() => {
    // eslint-disable-next-line no-void
    void fetchCommunityChannels();
  }, [fetchCommunityChannels]);

  const renderChannelStatus = () => {
    if (!isLoading) {
      if (channel) {
        // because channel.name has user-inputted data, we cannot use the normal approach
        // of using the translate function and passing the result into dangerouslySetInnerHtml
        return (
          <div>
            <span>{translate('Description.ConnectedTo')}</span>
            {/* eslint-disable-next-line react/jsx-no-literals */}
            <span className='font-bold'>{` #${channel.name} `}</span>
            <span>{translate('Label.Channel').toLocaleLowerCase()}</span>
          </div>
        );
      }
      return <div>{translate('Description.LinkNewChannel')}</div>;
    }
    return null;
  };

  const renderLinkChannelButton = () => {
    if (!isLoading && !channel) {
      return (
        <Button
          variant={Button.variants.secondary}
          size={Button.sizes.small}
          onClick={showChannelSelectorModal}>
          {translate('Action.LinkChannel')}
        </Button>
      );
    }
    return null;
  };

  const viewCommunityUrl = useMemo(
    () => groupAnnouncementsConstants.urls.getViewCommunityUrl(communityInfo.communityId),
    [communityInfo.communityId]
  );

  return (
    <div className='announcements-channel-configurator'>
      <div className='announcements-channel-configurator-community-icon-container'>
        <div className='announcements-channel-configurator-community-icon' />
      </div>
      <div className='announcements-channel-configurator-description'>
        <h2>{communityInfo.name}</h2>
        {renderChannelStatus()}
        <div className='announcements-channel-configurator-description-buttons'>
          <LinkableButton
            variant={Button.variants.cta}
            size={Button.sizes.small}
            href={viewCommunityUrl}
            label={translate('Action.ViewCommunity')}
          />
          {renderLinkChannelButton()}
        </div>
      </div>
      <div className='announcements-channel-configurator-menu'>
        <Popover
          id='announcements-channel-configurator-dropdown-menu'
          button={
            <IconButton iconName='more' size={IconButton.sizes.small} onClick={noOpFunctionRef} />
          }
          trigger='click'
          placement='bottom'>
          <ul className='dropdown-menu' role='menu'>
            <li>
              <Button variant={Button.variants.secondary} onClick={showChannelSelectorModal}>
                {translate('Label.ChangeChannel')}
              </Button>
            </li>
            <li>
              <Button variant={Button.variants.secondary} onClick={showUnlinkCommunityModal}>
                {translate('Label.UnlinkCommunity')}
              </Button>
            </li>
          </ul>
        </Popover>
      </div>
      {isUnlinkCommunityModalVisible && (
        <UnlinkCommunityDialog
          onConfirm={refetchCommunityInfo}
          onClose={hideUnlinkCommunityModal}
          groupId={groupId}
          communityInfo={communityInfo}
        />
      )}
      {isChannelSelectorModalVisible && (
        <ChangeChannelDialog
          groupId={groupId}
          communityInfo={communityInfo}
          onClose={hideChannelSelectorModal}
          onUpdateSuccess={onChannelUpdateSuccess}
        />
      )}
    </div>
  );
};

export default withTranslations(AnnouncementChannelConfigurator, groupAnnouncementsConfig);
