import React from 'react';
import classNames from 'classnames';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { groupAnnouncementsConfig } from '../translation.config';
import { GuildedUser } from '../types';

type Props = {
  guildedUser: GuildedUser;
  caption?: string;
} & WithTranslationsProps;

const GuildedUserInfoDisplay = ({ guildedUser, caption, translate }: Props): JSX.Element => {
  return (
    <div className='guilded-user-info-display'>
      <img
        className={classNames(
          'guilded-user-info-display-avatar',
          !guildedUser.profilePicture && 'guilded-user-info-display-avatar-default'
        )}
        src={guildedUser.profilePicture}
        title={translate('Label.GuildedAvatarAlt')}
        alt=''
      />
      {caption && <div className='guilded-user-info-display-caption'>{caption}</div>}
      <h3 className='guilded-user-info-display-username'>{guildedUser.username}</h3>
    </div>
  );
};

GuildedUserInfoDisplay.defaultProps = {
  caption: ''
};

export default withTranslations(GuildedUserInfoDisplay, groupAnnouncementsConfig);
