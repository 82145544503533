import { httpService } from 'core-utilities';
import { UserData, UserResponse } from '../types';
import groupAnnouncementsConstants from '../constants/groupAnnouncementsConstants';

export default {
  fetchUserInfo: async (userId: number): Promise<UserData[]> => {
    const url = groupAnnouncementsConstants.urls.getUsersInfoURL;
    const params = { userIds: [userId] };
    const urlConfig = {
      url,
      withCredentials: false
    };
    const { data } = await httpService.post<UserResponse>(urlConfig, params);
    return data.data;
  }
};
