import React, { useCallback } from 'react';
import { Button, SimpleModal } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import communityLinksService from '../../services/communityLinksService';
import { groupAnnouncementsConfig } from '../../translation.config';
import { logCommunityUnlinkFlowCompleted } from '../../utils/logging';
import { CommunityInfo } from '../../types';

type Props = {
  onConfirm: () => Promise<void>;
  onClose: () => void;
  groupId: number;
  communityInfo: CommunityInfo;
} & WithTranslationsProps;

const UnlinkCommunityDialog = ({
  onConfirm,
  onClose,
  groupId,
  communityInfo,
  translate
}: Props): JSX.Element => {
  const deleteLinkedCommunityFetch = useCallback(async () => {
    try {
      await communityLinksService.deleteLinkedCommunity(groupId);
      logCommunityUnlinkFlowCompleted({
        groupId,
        communityId: communityInfo.communityId,
        announcementChannelId: communityInfo.announcementChannelId
      });
      await onConfirm();
    } finally {
      onClose();
    }
  }, [groupId, communityInfo.communityId, communityInfo.announcementChannelId, onClose, onConfirm]);
  const modalBody = <p>{translate('Description.UnlinkCommunity')}</p>;
  return (
    <SimpleModal
      show
      title={translate('Label.UnlinkCommunity')}
      body={modalBody}
      actionButtonShow
      actionButtonText={translate('Action.Unlink')}
      actionButtonVariant={Button.variants.alert}
      neutralButtonText={translate('Action.Cancel')}
      onAction={deleteLinkedCommunityFetch}
      onClose={onClose}
      onNeutral={onClose}
    />
  );
};

export default withTranslations(UnlinkCommunityDialog, groupAnnouncementsConfig);
