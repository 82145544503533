import React from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';

import { Button } from 'react-style-guide';
import { groupAnnouncementsConfig } from '../translation.config';
import NewTabIcon from '../../../../images/new_tab_icon.svg';
import LinkableButton from './LinkableButton';
import groupAnnouncementsConstants from '../constants/groupAnnouncementsConstants';
import { CommunityInfo } from '../types';

export type ViewAllGuildedAnnouncementsButtonProps = {
  communityInfo: CommunityInfo;
} & WithTranslationsProps;

const ViewAllGuildedAnnouncementsButton = ({
  communityInfo,
  translate
}: ViewAllGuildedAnnouncementsButtonProps): JSX.Element => {
  return (
    <LinkableButton
      href={groupAnnouncementsConstants.urls.getViewAnnouncementChannelUrl(
        communityInfo.communityId,
        communityInfo.announcementChannelId
      )}
      variant={Button.variants.secondary}
      label={translate('Action.ViewAllAnnouncements')}
      iconURL={NewTabIcon}
    />
  );
};

export default withTranslations(ViewAllGuildedAnnouncementsButton, groupAnnouncementsConfig);
