import React, { useCallback, useState } from 'react';
import { Modal } from 'react-style-guide';
import { EnvironmentUrls } from 'Roblox';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { groupAnnouncementsConfig } from '../../../../translation.config';
import DialogActionButtonsRow from '../../DialogActionButtonsRow';
import CommunityAccountFetchStage from '../common/CommunityAccountFetchStage';

type Props = {
  handleCancel: () => void;
  handleContinue: () => void;
  handleError: () => void;
} & WithTranslationsProps;

const LinkCommunityAccountFetchStage = ({
  handleCancel,
  handleContinue,
  handleError,
  translate
}: Props): JSX.Element => {
  const [isAccountNotFound, setIsAccountNotFound] = useState(false);

  const handleAccountNotFound = useCallback(() => {
    setIsAccountNotFound(true);
  }, []);

  if (isAccountNotFound) {
    return (
      <Modal.Body>
        <div className='link-community-account-not-found-image' />
        <div className='community-dialog-section'>
          {translate('Description.GoToGuildedToLinkYourAccounts')}
        </div>
        <DialogActionButtonsRow
          primaryButtonProps={{
            label: translate('Action.ContinueToGuilded'),
            href: EnvironmentUrls.guildedBaseUrl
          }}
          secondaryButtonProps={{
            label: translate('Action.Cancel'),
            onClick: handleCancel
          }}
        />
      </Modal.Body>
    );
  }

  return (
    <CommunityAccountFetchStage
      handleContinue={handleContinue}
      handleCancel={handleCancel}
      handleAccountNotFound={handleAccountNotFound}
      handleError={handleError}
    />
  );
};

export default withTranslations(LinkCommunityAccountFetchStage, groupAnnouncementsConfig);
