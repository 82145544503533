import React from 'react';
import { Button } from 'react-style-guide';
import LinkableButton from '../LinkableButton';

type ButtonProps = {
  label: string;
  href?: string;
  variant?: string;
  disabled?: boolean;
  onClick?: () => void;
};

type Props = {
  primaryButtonProps?: ButtonProps;
  secondaryButtonProps?: ButtonProps;
};

const DialogActionButtonsRow = ({
  primaryButtonProps,
  secondaryButtonProps
}: Props): JSX.Element => {
  return (
    <div className='dialog-action-buttons-row'>
      {!!primaryButtonProps && (
        <LinkableButton variant={Button.variants.primary} {...primaryButtonProps} />
      )}
      {!!secondaryButtonProps && (
        <LinkableButton variant={Button.variants.secondary} {...secondaryButtonProps} />
      )}
    </div>
  );
};

DialogActionButtonsRow.defaultProps = {
  primaryButtonProps: undefined,
  secondaryButtonProps: undefined
};

export default DialogActionButtonsRow;
