import { eventStreamService } from 'core-roblox-utilities';
import {
  EventType,
  EventContext,
  EventDialogType,
  EventDialogState,
  EventEntityType
} from '../constants/events';

export const logCommunityCreateFlowStarted = ({
  groupId,
  context
}: {
  groupId: number;
  context: string;
}): void => {
  eventStreamService.sendEventWithTarget(EventType.CommunityDialogStateChanged, context, {
    dialogType: EventDialogType.Create,
    dialogState: EventDialogState.Started,
    entityType: EventEntityType.Group,
    entityId: groupId
  });
};

export const logCommunityCreateFlowCompleted = ({
  groupId,
  communityId,
  announcementChannelId,
  context
}: {
  groupId: number;
  communityId: string;
  announcementChannelId: string;
  context: string;
}): void => {
  eventStreamService.sendEventWithTarget(EventType.CommunityDialogStateChanged, context, {
    dialogType: EventDialogType.Create,
    dialogState: EventDialogState.Completed,
    entityType: EventEntityType.Group,
    entityId: groupId,
    guildedServerId: communityId,
    announcementChannelId
  });
};

export const logCommunityLinkFlowStarted = ({
  groupId,
  context
}: {
  groupId: number;
  context: string;
}): void => {
  eventStreamService.sendEventWithTarget(EventType.CommunityDialogStateChanged, context, {
    dialogType: EventDialogType.Link,
    dialogState: EventDialogState.Started,
    entityType: EventEntityType.Group,
    entityId: groupId
  });
};

export const logCommunityLinkFlowCompleted = ({
  groupId,
  communityId,
  announcementChannelId,
  context
}: {
  groupId: number;
  communityId: string;
  announcementChannelId: string;
  context: string;
}): void => {
  eventStreamService.sendEventWithTarget(EventType.CommunityDialogStateChanged, context, {
    dialogType: EventDialogType.Link,
    dialogState: EventDialogState.Completed,
    entityType: EventEntityType.Group,
    entityId: groupId,
    guildedServerId: communityId,
    announcementChannelId
  });
};

export const logCommunityChangeChannelFlowStarted = ({
  groupId,
  communityId,
  announcementChannelId
}: {
  groupId: number;
  communityId: string;
  announcementChannelId: string;
  context?: string;
}): void => {
  eventStreamService.sendEventWithTarget(
    EventType.CommunityDialogStateChanged,
    EventContext.ConfigureGroup,
    {
      dialogType: EventDialogType.ChangeChannel,
      dialogState: EventDialogState.Started,
      entityType: EventEntityType.Group,
      entityId: groupId,
      guildedServerId: communityId,
      announcementChannelId
    }
  );
};

export const logCommunityChangeChannelFlowCompleted = ({
  groupId,
  communityId,
  announcementChannelId,
  prevAnnouncementChannelId
}: {
  groupId: number;
  communityId: string;
  announcementChannelId: string;
  prevAnnouncementChannelId: string;
  context?: string;
}): void => {
  eventStreamService.sendEventWithTarget(
    EventType.CommunityDialogStateChanged,
    EventContext.ConfigureGroup,
    {
      dialogType: EventDialogType.ChangeChannel,
      dialogState: EventDialogState.Completed,
      entityType: EventEntityType.Group,
      entityId: groupId,
      guildedServerId: communityId,
      announcementChannelId,
      prevAnnouncementChannelId
    }
  );
};

export const logCommunityUnlinkFlowStarted = ({
  groupId,
  communityId,
  announcementChannelId
}: {
  groupId: number;
  communityId: string;
  announcementChannelId: string;
  context?: string;
}): void => {
  eventStreamService.sendEventWithTarget(
    EventType.CommunityDialogStateChanged,
    EventContext.ConfigureGroup,
    {
      dialogType: EventDialogType.Unlink,
      dialogState: EventDialogState.Started,
      entityType: EventEntityType.Group,
      entityId: groupId,
      guildedServerId: communityId,
      announcementChannelId
    }
  );
};

export const logCommunityUnlinkFlowCompleted = ({
  groupId,
  communityId,
  announcementChannelId
}: {
  groupId: number;
  communityId: string;
  announcementChannelId: string;
  context?: string;
}): void => {
  eventStreamService.sendEventWithTarget(
    EventType.CommunityDialogStateChanged,
    EventContext.ConfigureGroup,
    {
      dialogType: EventDialogType.Unlink,
      dialogState: EventDialogState.Completed,
      entityType: EventEntityType.Group,
      entityId: groupId,
      guildedServerId: communityId,
      announcementChannelId
    }
  );
};

export const logGroupPageExposureEvent = ({
  context,
  groupId,
  exposureType,
  exposureId
}: {
  groupId: number;
  exposureType: string;
  exposureId?: string;
  context: string;
}): void => {
  eventStreamService.sendEventWithTarget(EventType.GroupPageExposureEvent, context, {
    groupId,
    exposureType,
    ...(exposureId && { exposureId }) // fixes type-checking issue when undefined
  });
};

export const logGroupPageClickEvent = ({
  context,
  groupId,
  clickTargetType,
  clickTargetId
}: {
  groupId: number;
  clickTargetType: string;
  clickTargetId?: string;
  context: string;
}): void => {
  eventStreamService.sendEventWithTarget(EventType.GroupPageClickEvent, context, {
    groupId,
    clickTargetType,
    ...(clickTargetId && { clickTargetId }) // fixes type-checking issue when undefined
  });
};
