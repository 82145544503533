import React, { useCallback, useEffect, useState } from 'react';
import { Loading } from 'react-style-guide';
import communityLinksService from '../../../../services/communityLinksService';
import { CommunityInfo } from '../../../../types';
import CommunityLinkSuccessStage from '../common/CommunityLinkSuccessStage';
import { logCommunityLinkFlowCompleted } from '../../../../utils/logging';

type Props = {
  groupId: number;
  communityId: string;
  announcementChannelId: string | null;
  eventContext: string;
  handleClose: () => void;
  handleError: () => void;
};

const LinkCommunityCreateLinkStage = ({
  groupId,
  communityId,
  announcementChannelId,
  eventContext,
  handleClose,
  handleError
}: Props): JSX.Element => {
  const [linkedCommunity, setLinkedCommunity] = useState<CommunityInfo>();

  const linkCommunity = useCallback(async () => {
    try {
      const response = await communityLinksService.linkCommunity(
        groupId,
        communityId,
        announcementChannelId
      );
      setLinkedCommunity(response);
      logCommunityLinkFlowCompleted({
        groupId,
        communityId,
        announcementChannelId: response.announcementChannelId,
        context: eventContext
      });
    } catch {
      handleError();
    }
  }, [groupId, communityId, announcementChannelId, eventContext, handleError]);

  useEffect(() => {
    // eslint-disable-next-line no-void
    void linkCommunity();
  }, [linkCommunity]);

  if (!linkedCommunity) {
    return <Loading />;
  }

  return (
    <CommunityLinkSuccessStage
      groupId={groupId}
      communityInfo={linkedCommunity}
      handleClose={handleClose}
    />
  );
};

export default LinkCommunityCreateLinkStage;
