import React, { Fragment, useCallback, useState } from 'react';
import { Modal } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import LinkCommunityAccountFetchStage from './stages/link/LinkCommunityAccountFetchStage';
import ChangeChannelChannelSelectStage from './stages/channel/ChangeChannelChannelSelectStage';
import ChangeChannelUpdateCommunityStage from './stages/channel/ChangeChannelUpdateCommunityStage';
import { groupAnnouncementsConfig } from '../../translation.config';
import { CommunityInfo } from '../../types';
import useDialogErrorState from '../../hooks/useDialogErrorState';
import CommunityErrorStage from './stages/common/CommunityErrorStage';

type Props = {
  groupId: number;
  communityInfo: CommunityInfo;
  onClose: () => void;
  onUpdateSuccess: () => Promise<void>;
} & WithTranslationsProps;

enum ChangeChannelStage {
  ACCOUNT_FETCH,
  CHANNEL_SELECT,
  UPDATE_COMMUNITY
}

const ChangeChannelDialog = ({
  groupId,
  communityInfo,
  onClose,
  onUpdateSuccess,
  translate
}: Props): JSX.Element => {
  const [currentStage, setCurrentStage] = useState<ChangeChannelStage>(
    ChangeChannelStage.ACCOUNT_FETCH
  );

  const [announcementChannelId, setAnnouncementChannelId] = useState<string>('');

  const { errorState, handleError, clearError } = useDialogErrorState();
  const { hasError, canRetry } = errorState;

  const onUpdateFlowCompleted = useCallback(() => {
    // eslint-disable-next-line no-void
    void onUpdateSuccess();
    onClose();
  }, [onUpdateSuccess, onClose]);

  const onModalClose = useCallback(() => {
    if (currentStage === ChangeChannelStage.UPDATE_COMMUNITY) {
      onUpdateFlowCompleted();
    } else {
      onClose();
    }
  }, [currentStage, onClose, onUpdateFlowCompleted]);

  const goToChannelSelectStage = useCallback(() => {
    setCurrentStage(ChangeChannelStage.CHANNEL_SELECT);
  }, []);

  const goToUpdateCommunityStage = useCallback((selectedAnnouncementChannelId: string) => {
    setAnnouncementChannelId(selectedAnnouncementChannelId);
    setCurrentStage(ChangeChannelStage.UPDATE_COMMUNITY);
  }, []);

  return (
    <Modal className='link-community-dialog' show size='md' backdrop='static'>
      <Modal.Header title={translate('Heading.ChangeAnnouncementChannel')} onClose={onModalClose} />
      {hasError ? (
        <CommunityErrorStage canRetry={canRetry} handleRetry={clearError} handleClose={onClose} />
      ) : (
        <Fragment>
          {currentStage === ChangeChannelStage.ACCOUNT_FETCH && (
            <LinkCommunityAccountFetchStage
              handleCancel={onClose}
              handleContinue={goToChannelSelectStage}
              handleError={handleError}
            />
          )}
          {currentStage === ChangeChannelStage.CHANNEL_SELECT && (
            <ChangeChannelChannelSelectStage
              communityId={communityInfo.communityId}
              handleCancel={onClose}
              handleContinue={goToUpdateCommunityStage}
              handleError={handleError}
            />
          )}
          {currentStage === ChangeChannelStage.UPDATE_COMMUNITY && (
            <ChangeChannelUpdateCommunityStage
              groupId={groupId}
              communityInfo={communityInfo}
              announcementChannelId={announcementChannelId}
              handleClose={onUpdateFlowCompleted}
              handleError={handleError}
            />
          )}
        </Fragment>
      )}
    </Modal>
  );
};

export default withTranslations(ChangeChannelDialog, groupAnnouncementsConfig);
