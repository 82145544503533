import { useCallback, useState, useMemo } from 'react';

type DialogErrorState = {
  hasError: boolean;
  canRetry: boolean;
};

type DialogErrorStateReturn = {
  errorState: DialogErrorState;
  handleError: () => void;
  clearError: () => void;
};

type HandleErrorOptions = {
  canRetry?: boolean;
};

const useDialogErrorState = (): DialogErrorStateReturn => {
  const [hasError, setHasError] = useState(false);
  const [canRetry, setCanRetry] = useState(true);

  const handleError = useCallback((options: HandleErrorOptions = {}) => {
    setCanRetry(options?.canRetry ?? true);
    setHasError(true);
  }, []);

  const clearError = useCallback(() => {
    setHasError(false);
  }, []);

  const errorState = useMemo(
    () => ({
      hasError,
      canRetry
    }),
    [hasError, canRetry]
  );

  return {
    errorState,
    handleError,
    clearError
  };
};

export default useDialogErrorState;
