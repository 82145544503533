import React, { useCallback, useEffect, useState } from 'react';
import { Loading, Modal } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { AxiosResponse } from 'axios';
import communityLinksService from '../../../../services/communityLinksService';
import GuildedUserInfoDisplay from '../../../GuildedUserInfoDisplay';
import { ApiError, GuildedUser } from '../../../../types';
import { groupAnnouncementsConfig } from '../../../../translation.config';
import DialogActionButtonsRow from '../../DialogActionButtonsRow';
import { isEmpty } from '../../../../utils/utils';

type Props = {
  handleCancel: () => void;
  handleContinue: () => void;
  handleAccountNotFound: () => void;
  handleError: () => void;
} & WithTranslationsProps;

const CommunityAccountFetchStage = ({
  handleCancel,
  handleContinue,
  handleAccountNotFound,
  handleError,
  translate
}: Props): JSX.Element => {
  const [guildedUser, setGuildedUser] = useState<GuildedUser | null>();
  const [isLoading, setIsLoading] = useState(true);

  const fetchGuildedUserInfo = useCallback(async () => {
    try {
      const {
        data,
        status
      }: AxiosResponse<GuildedUser> = await communityLinksService.getGuildedUser();
      if (status === 204 || isEmpty(data)) {
        handleAccountNotFound();
      } else {
        setGuildedUser(data);
        setIsLoading(false);
      }
    } catch (error) {
      if ((error as ApiError).status === 404) {
        handleAccountNotFound();
      } else {
        handleError();
      }
    }
  }, [handleAccountNotFound, handleError]);

  useEffect(() => {
    // eslint-disable-next-line no-void
    void fetchGuildedUserInfo();
  }, [fetchGuildedUserInfo]);

  if (isLoading || !guildedUser) {
    return <Loading />;
  }

  return (
    <Modal.Body>
      <GuildedUserInfoDisplay guildedUser={guildedUser} caption={translate('Label.ContinueAs')} />
      <div className='community-dialog-section'>
        {translate('Description.FetchedGuildedAccount')}
      </div>
      <DialogActionButtonsRow
        primaryButtonProps={{
          label: translate('Action.Continue'),
          onClick: handleContinue
        }}
        secondaryButtonProps={{
          label: translate('Action.Cancel'),
          onClick: handleCancel
        }}
      />
    </Modal.Body>
  );
};

export default withTranslations(CommunityAccountFetchStage, groupAnnouncementsConfig);
