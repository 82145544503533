import Roblox from 'Roblox';
import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import GroupAnnouncementsConfigSection, {
  GroupAnnouncementsConfigSectionProps
} from './containers/GroupAnnouncementsConfigSection';
import GroupAnnouncementsSection, {
  GroupAnnouncementsSectionProps
} from './containers/GroupAnnouncementsSection';
import '../../../css/groupShouts/groupShouts.scss';

const renderGroupAnnouncementsConfigSection = (
  container: Element,
  props: GroupAnnouncementsConfigSectionProps
) => {
  unmountComponentAtNode(container); // make sure we aren't double-rendering components
  render(<GroupAnnouncementsConfigSection {...props} />, container);
};
const renderGroupAnnouncementsSection = (
  container: Element,
  props: GroupAnnouncementsSectionProps
) => {
  unmountComponentAtNode(container); // make sure we aren't double-rendering components
  render(<GroupAnnouncementsSection {...props} />, container);
};

const GroupAnnouncementsService = {
  renderGroupAnnouncementsConfigSection,
  renderGroupAnnouncementsSection
};

Object.assign(Roblox, {
  GroupAnnouncementsService
});
